import React from 'react'
import Jobs from '../Components/Pages/Jobs'

const Home = () => {
  return (
    <>
    <div className="container">
        <Jobs/>
    </div>
    </>
  )
}

export default Home